// Generated by Framer (a417fcb)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/l6rHYi79svcFRVrC1q12/Ticker.js";
import MagText from "https://framerusercontent.com/modules/FCYp8FRx320SWq9QcLcn/qpET2t2Z0ryTCtBpcD2G/magtext.js";
const MagTextFonts = getFonts(MagText);
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["z3xycz15v"];

const serializationHash = "framer-3y8Ru"

const variantClassNames = {z3xycz15v: "framer-v-e2lptq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "z3xycz15v", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-e2lptq", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"z3xycz15v"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-cbwd4q-container"} data-framer-name={"Footer Logo Ticker"} layoutDependency={layoutDependency} layoutId={"puwp8SveA-container"} name={"Footer Logo Ticker"}><Ticker alignment={"flex-start"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 40, overflow: false}} gap={100} height={"100%"} hoverFactor={0.6} id={"puwp8SveA"} layoutId={"puwp8SveA"} name={"Footer Logo Ticker"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} sizingOptions={{heightType: true, widthType: true}} slots={[<motion.div className={"framer-1mhjii1"} data-framer-name={"MagicText"} layoutDependency={layoutDependency} layoutId={"G_3LgCru2"}><ComponentViewportProvider ><motion.div className={"framer-1gnc1h3-container"} layoutDependency={layoutDependency} layoutId={"G2WzaCFfk-container"}><MagText color={"var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))"} font={{fontFamily: "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", fontSize: "120px", fontStyle: "normal", fontWeight: 500, letterSpacing: "-0.06em", lineHeight: "1em"}} height={"100%"} id={"G2WzaCFfk"} layoutId={"G2WzaCFfk"} text={"FOR® Brand. FOR® Future."} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div>]} speed={50} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3y8Ru.framer-ui8l1n, .framer-3y8Ru .framer-ui8l1n { display: block; }", ".framer-3y8Ru.framer-e2lptq { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 16px 0px 16px 0px; position: relative; width: 1160px; }", ".framer-3y8Ru .framer-cbwd4q-container { flex: none; height: 120px; position: relative; width: 100%; }", ".framer-3y8Ru .framer-1mhjii1 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 121px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1365px; }", ".framer-3y8Ru .framer-1gnc1h3-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3y8Ru.framer-e2lptq, .framer-3y8Ru .framer-1mhjii1 { gap: 0px; } .framer-3y8Ru.framer-e2lptq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-3y8Ru.framer-e2lptq > :first-child { margin-left: 0px; } .framer-3y8Ru.framer-e2lptq > :last-child { margin-right: 0px; } .framer-3y8Ru .framer-1mhjii1 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-3y8Ru .framer-1mhjii1 > :first-child { margin-top: 0px; } .framer-3y8Ru .framer-1mhjii1 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 152
 * @framerIntrinsicWidth 1160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroQ6cPhFDJ: React.ComponentType<Props> = withCSS(Component, css, "framer-3y8Ru") as typeof Component;
export default FrameroQ6cPhFDJ;

FrameroQ6cPhFDJ.displayName = "Footer/Footer Logo ticker";

FrameroQ6cPhFDJ.defaultProps = {height: 152, width: 1160};

addFonts(FrameroQ6cPhFDJ, [{explicitInter: true, fonts: [{family: "Instrument Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-QfwmS0v3_7Y.woff2", weight: "500"}]}, ...MagTextFonts, ...TickerFonts], {supportsExplicitInterCodegen: true})